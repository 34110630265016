<template>
  <div>
    <vue-headful :title="pageheading" />
    <div
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px"
    >
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>
    <div style="max-width: 95%; margin: auto">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area" class="annual-questionnaire-form">
        <div>
          <form
            @submit.prevent="validateBeforeSubmit"
            style="margin: 0 auto;"
          >
            <div
              v-if="!isViewOnly"
              id="buttongroup"
              style="
                padding-bottom: 20px;
                width: 100%;
                display: flex;
                justify-content: flex-end !important;
                gap: 0.5rem;
              "
            >
              <div class="" style="max-width: 60px">
                <button :disabled="!$hasPermissions(clientSession, ['ANNUAL_QUESTIONNAIRE'], 2)" type="submit" class="button is-accent has-text-white">
                  Save
                </button>
              </div>
              <div class="">
                <a
                  class="button is-light"
                  v-on:click="
                    resetForm();
                    $router.go(-1);
                  "
                  >Cancel</a
                >
              </div>
            </div>
            <div class="columns"  v-if="!isViewOnly">
              <div class="column" style="max-width: 50%">
                Please answer all questions:

                <div class="block" style="margin-top: 3rem;">
                  <div class="flex-container" style="gap: 1rem;">

                    <TextInput v-model="name" label="Name" :isRequired="true" style="width: 320px" />
                    <WindowSelect
                      v-model="changes"
                      :dataItems="changeOptions"
                      label="Evaluation of the material changes to BOLI Risk Management Policies"
                      :isRequired="true"
                      style="width: 100%;"
                      @change="e => updateChanges(e)"
                    />

                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import Loading from '../Loading'
import TextInput from '../partials/forms/TextInput.vue'
import WindowSelect from '../partials/forms/WindowSelect.vue'

let pageName = 'Annual BOLI Review - Configure Client Report - {clientId}'

export default {
  components: {
    Loading,
    TextInput,
    WindowSelect
  },
  props: {
    model: {
      default: () => {
        return {
          id: 0,
          year: '',
          institution: '',
          changeOptions: [
            {
              active: true,
              text: 'No changes at this time'
            },
            {
              active: false,
              text: 'TBD'
            }
          ]
        }
      }
    }
  },
  data () {
    return {

      id: 0,
      year: '',
      years: [],

      BOLIReview: {},
      changeOptions: [
        {
          active: true,
          text: 'No changes at this time'
        },
        {
          active: false,
          text: 'TBD'
        }
      ]
    }
  },
  watch: {
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageheading () {
      return !this.isViewOnly ? 'Annual Questionnaire - @questionnaireName'.replace('@questionnaireName', this.name) : 'Annual Questionnaire Response - @questionnaireName'.replace('@questionnaireName', this.name)
    },
    filteredParticipants () {
      return this.participantTable && this.participantTable.length > 0
        ? this.showDeletedParticipants
          ? this.participantTable
          : this.participantTable.filter((c) => !c.isDeleted)
        : []
    },
    filteredContacts () {
      return this.contactTable && this.contactTable.length > 0
        ? this.showDeletedContacts
          ? this.contactTable
          : this.contactTable.filter((c) => !c.isDeleted)
        : []
    },
    QuestionnaireType () {
      if (this.templateTypeCategories && this.templateTypeCategories.length > 0) {
        return this.templateTypeCategories.find(x => x.description === 'Reporting Questionnaire')
      } else {
        return {}
      }
    },
    hasDeletedContacts () {
      return this.contactTable && this.contactTable.length > 0
        ? this.contactTable.filter((c) => c.isDeleted).length > 0
        : false
    },
    hasDeletedParticipants () {
      return this.participantTable && this.participantTable.length > 0
        ? this.participantTable.filter((c) => c.isDeleted).length > 0
        : false
    },
    pageTitle () {
      return (pageName.replace('{clientId}', this.BOLIReview.clientId)) + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      let isCreateMode = !id || id <= 0

      // if (!isCreateMode) {
      //   pageName = 'Edit Questionnaire'
      // }
      return isCreateMode
    }
  },
  mounted () {
  },
  methods: {
    updateChanges (value) {
      this.changes = value
      this.$forceUpdate()
    },
    validateBeforeSubmit () {
    },
    async handleSubmitLocal (route, nav) {
      this.axios
        .patch(
          `annual-questionnaire/${this.$route.params.id}/reports/${this.$route.params.reportId}`
        )
        .then((response) => {
          if (response.status === 200) {
            this.successToast('Successfully saved Questionnaire!')
            this.$router.go(-1)
          }
        })
        .catch((error) => {
          this.handleApiErr(error)
        })
        .finally(() => {
          this.isLoading = false
        })

      // await this.handleSubmit(route, -1)
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}

.subheading {
  font-size: 1em;
  padding: 30px 0 10px 0;
}

div.flex-field {
  display: inline-block;
}

div.flex-field:not(:first-child) {
  padding-left: 20px;
}

.field {
  color: #4a4a4a;
}

.flex-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 0.5rem;
}

.text-left {
  text-align: left !important;
}

.flex-container .flex-field {
  padding: 0px !important;
  margin: 0px !important;
}

.flex-center {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-left {
  justify-content: flex-start !important;
}

.flex-full {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-full div {
  width: 100%;
}

.flex-full div div {
  width: 100%;
}

.flex-full div div input {
  width: 100%;
}

.field input,
.field textarea,
.flex-field input,
.column input,
.column textarea {
  font-size: 1em;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  min-height: 36px;
  color: #363636;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
}

/* Datatable CSS */
.v-datatable-light {
  display: inline-block;
  margin: 10px 0;
}
/* End Datatable CSS */

/* Invite Client CSS*/
.invite-client-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* padding-right: 2.8rem; */
}

.invite-client-wrapper .control > .select {
  display: flex;
  height: 16rem;
}

.annual-questionnaire-form input[disabled=true] {
  opacity: .75!important;
  background: #f0f0f0;
}
/* End Invite Client CSS */
</style>

<style scoped>
.column {
  padding: 0.5rem !important;
  margin: 0rem !important;
}

.show-deleted {
  position: absolute;
  right: 0;
  top: calc(50% - 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-deleted button:not(.button),
.show-deleted a:not(.button) {
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.show-deleted button:not(.button):hover,
.show-deleted a:not(.button):hover {
  text-decoration: underline;
}

</style>
