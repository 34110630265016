<template>
  <div class="textinput flex-field" :style="style">
    <label v-if="label" class="label is-size-7">
      {{ label }}
      <span v-if="isRequired" class="has-text-warning" style="padding-left: 3px">*</span>
    </label>
    <div class="field">
      <input class="textfield" type="text" :value="value" @input="updateValue"/>
    </div>
  </div>
</template>

<script>
// this is a reusable component
// we want to be able to have v-model on it
// and we want all native text-field events to bubble up
export default {
  props: {
    label: {
      type: String,
      required: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      required: true
    },
    style: {
      type: String,
      required: false
    }
  },
  methods: {
    updateValue: function (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style scoped>
.textinput .flex-field {
  width: 100%!important;
}

.textinput .flex-field label {
  display: block;
}

.textinput .flex-field .field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%!important;
}

input.textfield {
  min-width: 100%!important;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
}
</style>
